<template>
  <main>
    <CContainer>
      <CForm>
        <CCard>
          <CCardHeader>
            <CRow class="d-flex align-items-center">
              <CCol col="6">
                <h5 class="mb-0">{{ $t('Navigation.Message/MailTemplate') }}</h5>
              </CCol>
              <CCol col="6">
                <div class="card-header-actions d-flex">
                  <CButton color="success" type="button" @click="AddTemplates()">
                    {{ $t('Global.Add') }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="6" lg="4" xl="3" v-for="item in List" :key="item.Slug">
                <CCard>
<!--                  <CCardImg :src="item.Cover || 'https://firebasestorage.googleapis.com/v0/b/ksong-eb5c0/o/Assets%2Fno_image.jpg?alt=media&token=cbad99bb-8101-4631-9d77-8951ff65f27b'" />-->
                  <CCardBody>
                    <h5 class="mb-0">{{ item.Name || item.Slug }}</h5>
                  </CCardBody>
                  <CCardFooter class="pt-0 border-0 d-flex">
                    <CButton color="success" block class="mr-2" @click="LoadTemplate(item.Slug)">{{ $t('Global.Edit') }}</CButton>
                    <CButton v-if="item.Slug !== 'Default'" color="danger" @click="DeleteTemplate(item.Slug)"><CIcon name="cil-trash" /></CButton>
                  </CCardFooter>
                </CCard>
              </CCol>
            </CRow>
            <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5">
              <CSpinner color="info"/>
            </CElementCover>
          </CCardBody>
          <CCardFooter>
            <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="GetTemplateList()"
            />
          </CCardFooter>
        </CCard>
      </CForm>
    </CContainer>
    <CModal
      id="EmailTemplateModal"
      title="edit"
      :show.sync="ShowModal"
      :closeOnBackdrop="false"
      size="xl"
      @update:show="HandleClose"
    >
      <div id="LayerEditor">
        <CInput
          v-model="TemplateData.Name"
          type="string"
          horizontal
        >
          <template #prepend-content>
            <span>模板名稱</span>
          </template>
        </CInput>
        <component :is="WebEditor" ref="webEditor" :projectId="67635" locale="zh-TW" :options="Options" />
      </div>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h4>{{ Action === 'Add' ? $t('Global.Add') : $t('Global.Edit') }}{{ $t('Navigation.Message/MailTemplate') }}</h4>
          <CButtonClose @click="ShowModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <footer class="modal-footer border-top-0">
          <CButton color="info" @click="ShowVariable()"><i class="fas fa-info-circle mr-2" />{{ $t('Message/MailTemplate.VariableLabel') }}</CButton>
          <CButton @click="ShowModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton @click="SaveTemplate()" color="success">
            {{ $t('Global.Save') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "郵件模板"
  }
}
</route>

<script>
export default {
  name: 'EditMailTemplates',
  layout: 'manage',
  data () {
    return{
      Data: {
        Layout: '',
        Templates: {}
      },
      TemplateData: {
        Taxonomy: 'Page',
        Variable: {}
      },
      WebEditor: null,
      Loading: false,
      ShowModal: false,
      EditingCategory: '',
      TempList: ['Default'],
      InsertButtons: {
        'Title': '{:TITLE:}',
        'Logo': '{:LOGO:}',
        'SiteName': '{:SITENAME:}',
        'Email': '{:EMAIL:}',
        'Tel': '{:TEL:}',
        'Address': '{:ADDRESS:}',
        'Content': '{:CONTENT:}',
        'Account': '{:ACCOUNT:}',
        'BuyerName': '{:BUYERNAME:}',
        'BuyerPhone': '{:BUYERPHONE:}',
        'OrderNum': '{:ORDERNO:}',
        'LogisticsStatusText': '{:SHIPMENT_STATUS:}'
      },
      Action: 'Add',
      List: [],
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Options: {
        displayMode: 'email'
      },
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Cover', label: '預覽' },
        { key: 'Name', label: this.$t('Post/List.Name') },
        { key: 'Action', label: '' }
      ]
    }
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetTemplateList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetTemplateList () {
      const Data = {
        Taxonomy: 'EmailTemplate',
        Page: this.Pagination.Page,
        PerPage: this.Pagination.PerPage
      }
      this.List = []
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/list',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.List = (data.Data || []).map(item => {
          return {
            Name: item.Name,
            Cover: item.Cover,
            Slug: item.Slug
          }
        })
        if (!this.List.map(item => item.Slug).includes('Default')) {
          this.List.push({
            Name: '預設模板',
            Cover: '',
            Slug: 'Default'
          })
        }
        this.Pagination = data.Pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    AddTemplates () {
      this.WebEditor = () => import('@/plugins/unlayer/WebEditor')
      this.Action = 'Add'
      this.ShowModal = true
      this.TemplateData = {
        Status: 1,
        Taxonomy: 'EmailTemplate',
        Variable: {},
        Name: null,
        Slug: null
      }
    },
    LoadTemplate (slug) {
      this.WebEditor = () => import('@/plugins/unlayer/WebEditor')
      this.Loading = true
      this.ShowModal = true
      this.Action = 'Edit'
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/detail',
          Data: {
            Taxonomy: 'EmailTemplate',
            Slug: slug,
            Manage: true
          }
        }
      }).then(({data}) => {
        this.Loading = false
        // 有資料則改為編輯動作，沒資料則為新增動作
        if (data.Data !== null) {
          this.$refs.webEditor.editor.loadDesign(data.Data.Variable.Design)
          this.TemplateData = data.Data
        } else {
          this.Action = 'Add'
          this.TemplateData = {
            Taxonomy: 'EmailTemplate',
            Variable: {},
            Status: 1,
            Name: slug === 'Default' ? '預設模板' : null,
            Slug: slug === 'Default' ? 'Default' : null
          }
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    SaveTemplate () {
      const SendData = {}
      const Slug = this.TemplateData.Slug || Math.random().toString(36).substring(3, 8).toUpperCase()
      this.Loading = true
      this.$refs.webEditor.editor.exportHtml((data) => {
        if (this.Action === 'Edit') {
          SendData.URL = '/content/article/edit'
          SendData.Data = {
            Slug: Slug,
            Taxonomy: 'EmailTemplate',
            UpdateData: {
              Name: this.TemplateData.Name,
              Slug: Slug,
              'Variable.HTML': data.html,
              'Variable.Design': data.design
            }
          }
        } else {
          this.TemplateData.Variable.HTML = data.html
          this.TemplateData.Variable.Design = data.design
          this.TemplateData.Slug = Slug
          this.TemplateData.Taxonomy = 'EmailTemplate'
          SendData.URL = '/content/article/add'
          SendData.Data = this.TemplateData
        }
        return Promise.all([
          this.$store.dispatch('InnerRequest', {
            url: '/emailNotify/templates',
            method: 'post',
            data: {
              Category: Slug,
              UpdateData: data.html
            }
          }),
          this.$store.dispatch('InnerRequest', {
            url: '/service/action',
            method: 'post',
            data: SendData
          })
        ]).then(() => {
          this.GetTemplateList()
          this.Loading = false
          this.ShowModal = false
          this.WebEditor = null
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.Global/SaveSuccess'),
            type: 'success'
          })
        }).catch((err) => {
          this.Loading = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.Global/SaveFail') + err,
            type: 'error'
          })
        })
      })
    },
    DeleteTemplate (slug) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.AskDelete'),
        text: '此操作將不可回覆，請您再次確認是否刪除郵件模板？',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.Loading = true
          return Promise.all([
            this.$store.dispatch('InnerRequest', {
              url: '/emailNotify/templates/delete',
              method: 'post',
              data: {
                Category: slug
              }
            }),
            this.$store.dispatch('InnerRequest', {
              url: '/service/action',
              method: 'post',
              data: {
                URL: '/content/article/delete',
                Data: {
                  Taxonomy: 'EmailTemplate',
                  Slug: slug,
                  Manage: true
                }
              }
            })
          ]).then(() => {
            this.Loading = false
            this.GetTemplateList()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Global/DeleteSuccess'),
              type: 'success'
            })
          }).catch((error) => {
            this.Loading = false
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Global/DeleteFail') + error.msg,
              type: 'error'
            })
          })
        }
      })
    },
    ShowVariable() {
      let html = ''
      Object.keys(this.InsertButtons).forEach((str) => {
        html += '<div class="small mb-2">' + this.$t(`Message/MailTemplate.TableLabels.${str}`) + ': ' + this.InsertButtons[str] + '</div>'
      })
      this.$swal({
        title: this.$t('Message/MailTemplate.VariableLabel'),
        html,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
      })
    },
    HandleClose (value) {
      if (!value) {
        this.WebEditor = null
        setTimeout(() => {
          this.Editor.destroy()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#LayerEditor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  height: 100%;
  .unlayer-frame,.unlayer-editor {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
</style>
